































import { Component, Vue, Prop } from 'vue-property-decorator'
import { hasPermissions } from '@/utils/auth'
import axios from 'axios'
import { Gvd } from '@/store/modules/gvd'
import { EventBus } from '@/utils/event-bus'
import { saveAs } from 'file-saver'

@Component
export default class GvdDownloader extends Vue {
  @Prop() gvd?: Gvd

  get gvdKey () {
    return this.gvd ? this.gvd.key : ''
  }

  get gvdTitle () {
    return this.gvd ? this.gvd.title : ''
  }

  async download (format: string, e: Event) {
    let _format = format || 'pdf'
    // if (!this.onlyOneOption) {
    //   return
    // }
    e.preventDefault()
    e.stopPropagation()
    _format = _format || (hasPermissions(['gvd.download.pdf']) ? 'pdf' : 'docx')
    EventBus.$emit('show-loader')
    const resp = await axios.get(`gvds/download-${_format}/${this.gvdKey}`, { withCredentials: true, responseType: 'blob' })
    if (!resp || resp.status !== 200) {
      EventBus.$emit('hide-loader')
      return
    }
    const _blob = new Blob([resp.data], { type: _format === 'pdf' ? 'application/pdf' : 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' })
    saveAs(_blob, `${this.gvdTitle}${_format === 'pdf' ? '.pdf' : '.docx'}`)
    EventBus.$emit('hide-loader')
  }

  get canDownload () {
    return hasPermissions(['gvd.download.pdf']) || hasPermissions(['gvd.download.docx'])
  }

  get onlyOneOption () {
    const _docxFilename = this.gvd ? this.gvd.docxFilename : ''
    const _docxFileExists = (_docxFilename && _docxFilename.length)
    return (hasPermissions(['gvd.download.pdf']) && !hasPermissions(['gvd.download.docx'])) ||
      (!hasPermissions(['gvd.download.pdf']) && hasPermissions(['gvd.download.docx'])) ||
      !_docxFileExists
  }

  hasPermissions = hasPermissions
}
