




































import { Component, Vue, Prop } from 'vue-property-decorator'
import { Resource } from '@/store/modules/resource'
import { EventBus } from '@/utils/event-bus'
import { lookup } from 'mime-types'
import { saveAs } from 'file-saver'
import axios from 'axios'

@Component
export default class GVDReferences extends Vue {
  @Prop({ default: [] }) references!: Resource[]

  openReferencesModal () {
    this.$bvModal.show('all-references-modal')
  }

  getRefNum (reference: Resource) {
    return reference.gvdResourceNumbers.length ? `${reference.gvdResourceNumbers[0].number}.` : '1.'
  }

  get numberedReferences () {
    return this.references.filter((reference: Resource) => {
      return reference.gvdResourceNumbers.length
    })
  }

  get unnumberedReferences () {
    return this.references.filter((reference: Resource) => {
      return !reference.gvdResourceNumbers.length
    })
  }

  get hasReferences () {
    return this.numberedReferences.length || this.unnumberedReferences.length
  }

  async downloadFile (resource: Resource) {
    EventBus.$emit('show-loader')
    const resp = await axios.get(`resources/download/${resource.key}`, { withCredentials: true, responseType: 'blob' })
    if (!resp || resp.status !== 200) {
      EventBus.$emit('hide-loader')
      return
    }
    const _blob = new Blob([resp.data], { type: lookup(resource.filename as string) || undefined })
    saveAs(_blob, resource.filename)
    // const url = window.URL.createObjectURL(new Blob([resp.data]))
    // const link = document.createElement('a')
    // link.href = url
    // link.setAttribute('download', `${resource.filename}`)
    // link.click()
    // link.remove()
    EventBus.$emit('hide-loader')
  }
}
