




































import { Component, Vue, Prop } from 'vue-property-decorator'
import GvdResourceList from './GvdResourceList.vue'
import { Resource, ResourceType } from '@/store/modules/resource'

@Component({
  components: {
    GvdResourceList
  }
})
export default class GvdResources extends Vue {
  show: boolean = false;
  @Prop(String) currentPageKey?: string;
  @Prop() currentPageResources?: Resource[];
  // @Prop(Number) currentPage!: number

  get resourceCount () {
    return this.currentPageResources ? this.currentPageResources.length : 0
  }

  get resourceTypes () {
    return this.$store.getters.getResourceTypes.sort(
      (a: ResourceType, b: ResourceType) => {
        return a.name === 'References' ||
          a.name.toLowerCase() < b.name.toLowerCase()
          ? -1
          : a.name.toLowerCase() > b.name.toLowerCase()
            ? 1
            : 0
      }
    )
  }

  toggleResources (): void {
    this.show = !this.show
  }

  getPulloutBgColor () {
    if (this.show) {
      return this.$store.getters.getBGStyle3
    } else {
      return this.$store.getters.getBGStyle1
    }
  }
}
