







import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class GvdSectionSelect extends Vue {
  @Prop({ default: [] })
  options!: any[]

  @Prop({ default: '' })
  value!: string

  get currentGvdSectionKey () {
    return this.value
  }

  set currentGvdSectionKey (newKey: string) {
    this.$emit('input', newKey)
  }
}
